    <template>
  <div>
    <div class="col-12">
      <div class="m-2">
        <Button class="ml-2" label="VOLVER" @click="this.$router.go(-1)" />
      </div>

      <Panel header="Datos desarrollo" :toggleable="true" :collapsed="true">
	
              <pre>

      {{ order }}

      </pre>
      </Panel>

      
      <div class="card" :key="index" v-if="loaded">
        <div class="flex justify-content-between flex-wrap">
          <div>
            <h1>{{ order.op.oc_number }}-{{ order.id}}  
              <span style="color: blueviolet">

              {{ order.op.client_name }}
            </span>
          </h1>
          </div>
          <div class="" v-if="order && order.pieza && order.pieza.plane">
            <Button
              class="p-button-danger ml-2"
              label="PLANO"
              icon="pi pi-file-pdf"
              @click="showPlane(order.pieza.plane.file)"
            />
          </div>
        </div>

        <div class="">
          <div class="formgrid grid">
            <div class="field col">
              <label for="detail">PIEZA</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.piece_name }}
              </span>
            </div>

            <div class="field col">
              <label for="detail">CANTIDAD</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.quantity }}
              </span>
            </div>
          </div>
        </div>

        <div
          v-if="
            (order.pieza &&
            !order.pieza.formula)
            ||
            (order.pieza.formula &&
            order.pieza.formula.materiales.length == 0)
          "
        >
          <InlineMessage severity="error"
            >PIEZA MAL CONFIGURADA. NO TIENE MATERIALES.</InlineMessage
          >
          <Button class="p-button-primary ml-2" label="CONFIGURAR" @click="this.$router.push({name: 'edit-piece', params: {id: order.pieza.id}})" />

        </div>

        <div v-if="order.mpc">
          <Message severity="warn" >La materia prima será recibida del cliente.  </Message>
        </div>
        {{ order.mpc }}
        <div class="" v-if="order.pieza && order.pieza.formula">
          <div
            class="formgrid grid"
            v-for="(mat, index) in order.pieza.formula.materiales"
            :key="index"
          >
            <div class="field col-4">
              <label for="detail">MATERIA PRIMA # {{ index + 1 }}</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ mat.tipo }}

                {{
                  selectedMaterial[index]
                    ? sumCanUse(
                        selectedMaterial[index],
                        order.pieza.formula.materiales[index].pesobruto *
                          order.quantity
                      ).toFixed(2)
                    : 0
                }}
                /
                {{
                 ( order.pieza.formula.materiales[index].pesobruto *
                  order.quantity).toFixed(2)
                }}

                <!-- <div v-for="ms in selectedMaterial[index]">

                {{ ms.real_quantity}}

                </div> -->

                <div
                  v-if="
                    selectedMaterial[index] &&
                    order.pieza &&
                    order.pieza.formula.materiales &&
                    isEnoughMat(
                      selectedMaterial[index],
                      order.pieza.formula.materiales[index].pesobruto *
                        order.quantity
                    )
                    // selectedMaterial[index].quantity >=
                    //   order.pieza.formula.materiales[0].pesobruto *
                    //     order.quantity
                  "
                >
                  <InlineMessage severity="success"
                    >Material disponible para la pieza.</InlineMessage
                  >
                </div>
                <div v-else>
                  <hr />

                  <InlineMessage severity="error"
                    >Material insuficiente.</InlineMessage
                  >

                  <Button class="ml-2" label="Solicitar"> </Button>
                </div>
              </span>
            </div>
            <div class="field col-6">
              <label for="detail">UTILIZAR DE STOCK</label>
              <span id="detail" type="text" class="inputfield w-full">
                <AutoComplete
                  v-model="selectedMaterial[index]"
                  :suggestions="filteredMaterials"
                  @complete="searchMaterials($event)"
                  :dropdown="true"
                  :value="id"
                  id="base"
                  class="inputfield w-full"
                  :multiple="true"
                  v-if="order.pieza && order.pieza.formula && order.pieza.formula.materiales.length"
                >
                  <template #item="slotProps">
                    <div class="p-autocomplete-item-content">
                      <div class="p-autocomplete-item-name">
                        <strong>
                          {{ slotProps.item.material_code  }}
                        </strong>

                        
                        {{ slotProps.item.real_quantity }} {{ slotProps.item.unit ?? 'KG' }}


                        <span style="font-weight: bolder; color: #0078d4; margin-left: auto;">
                          {{ slotProps.item.provider_name }}
                        </span>

                      </div>
                      <div class="p-autocomplete-item-description">
                        {{ slotProps.item.name }}
                        
                      </div>
                    </div>
                  </template>
                  <template #chip="slotProps">
                    <div class="">
                      <div class="flex justify-content-between">
                        <div class="mr-2" >

                          <strong>{{ slotProps.value.material_code }}</strong> 
                        </div>
                        <div>{{ slotProps.value.name }}</div>
                        <div class="ml-2 p-badge p-badge-lg p-badge-info">
                          {{ slotProps.value.real_quantity }} {{ slotProps.value.unit ?? 'KG' }}
                        </div>
                      </div>
                     </div>
                    </template>
                </AutoComplete>
                <div
                  v-if="order.pieza && order.pieza.formula.materiales.length"
                >
                  <p class="text-sm">
                    <strong
                      >{{
                        (order.pieza.formula.materiales[index].pesobruto *
                        order.quantity).toFixed(2)
                      }}
                       </strong
                    >
                    de stock requeridos.
                  </p>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div class="" v-if="order && order.pieza && order.pieza.formula && order.pieza.formula.materiales.length" >
          <div class="formgrid grid">
            <div class="field col-12">
              <label for="detail"> OPERACIONES</label>

              <div>
                <div v-if="order.pieza && order.pieza.operaciones.length">
                  <div
                    v-for="(operacion, index) in order.pieza.operaciones"
                    :key="index"
                    class="flex justify-content-between mb-2"
                  >
                    <div class="uppercase">
                      <strong>
                        {{ operacion.name }}
                      </strong>
                    </div>
                    <div>
                      <!-- {{ operacion.estimated }} -->
                    </div>
                    <div>
                      <!-- 
                      {{ operacion.estimated * order.quantity }} -->
                    </div>
                    <div>
                      <SelOpForOtiOp
                        :order="order"
                        :operation="operacion"
                        :operators="operators"
                        :index="index"
                        :key="index"
                        :selectedOP="this.opforop[index]"
                        :machines="machines"
                        @update="updateOperation($event, index)"
                      />
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p class="text-sm">
                    <InlineMessage severity="error"
                      >No hay operaciones para la pieza, agreguelas para
                      continuar.</InlineMessage
                    >
                  </p>
                </div>
                <div class="mt-3 form grid">
                  <div class="field col-5">
                    <InputText
                      v-model="operation"
                      id="detail"
                      type="text"
                      class="inputfield w-full"
                    />
                  </div>
                  <div class="field col-5">
                    <label for="detail"> </label>
                    <Button
                      @click="addOperation()"
                      icon="pi pi-plus"
                      class="p-button-sm p-button-success"
                    />
                  </div>
                </div>
                <Button
                  @click="updateOperations()"
                  icon="pi pi-save"
                  class="p-button-sm p-button-success"
                  label="Actualizar operaciones de la pieza"
                />

              </div>
            </div>
          </div>
          <div class="mt-3 form grid">
            <div class="col-12">
              <Button
                @click="setParams()"
                :disabled="!allEnough()"
                class="p-button-success w-full text-center"
              >
                <h3>ASIGNAR MATERIALES Y OPERARIOS</h3>
              </Button>
            </div>
          </div> 
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import OtiClock from "./../../components/OtiClock.vue";
import CrudService from "./../../services/crud.service.js";
import OperatorService from "./../../services/operator.service.js";
import SelOpForOtiOp from "./../../components/SelOpForOtiOp.vue";
import otiService from "../../services/oti.service";

export default {
  components: {
    OtiClock,
    SelOpForOtiOp,
  },
  data() {
    return {
      loaded: false,
      order: [],
      machines: [],
      clients: [],
      users: [],
      operators: [],
      operation: "",
      opforop: [],
      maforop: [],
      operaciones: [],
      materiales: [],
      machines: [],
      tratamientos: [
        { id: 1, name: "Tratamiento 1" },
        { id: 2, name: "Tratamiento 2" },
        { id: 3, name: "Tratamiento 3" },
        { id: 4, name: "Tratamiento 4" },
      ],
      selectedMaterial: [],
      filteredMaterials: [],
      lodaded: false
    };
  },
  created() {



  },
  mounted() {
    this.getOrder();
    this.getOperators();


  },
  computed: {},
  methods: {
    setParams() {
      console.log(this.selectedMaterials);
      console.log(this.opforop);

      let params = {
        order: this.order,
        materials: this.selectedMaterial,
        primas: this.order.pieza.formula.materiales,
        operations: this.opforop,
        machines: this.maforop,
      };

      otiService.setParams(params).then(
        (response) => {
          console.log(response);
          this.$router.push({ name: "oti", params: { id: this.order.id } });
        },
        (error) => {
          console.log(error);
        }
      );
    },
    updateOperation(e,i) {   
      console.log(e)    
      this.opforop[i] = e[0];
      this.maforop[i] = e[1];
    },
    allEnough() {
      if (!this.loaded) return false;
      if (!this.selectedMaterial) return false;

      let enough = [];

      this.order.pieza.formula.materiales.forEach((material, index) => {
        let meta = material.pesobruto * this.order.quantity;
        //if(!this.selectedMaterial[index] && !this.isEnoughMats(this.selectedMaterial[index],meta)) return false

        enough.push(this.isEnoughMat(this.selectedMaterial[index], meta));
      });

      // length of enough
      let l = enough.length;
      if (l == 0) return false;

      // !!array.reduce(function(a, b){ return (a === b) ? a : NaN; });

      return !!enough.reduce(function (a, b) {
        return a === b ? a : NaN;
      });
    },
    isEnoughMats(mats, meta) {
      console.log(mats, meta);
      return this.sumCanUse(mats, meta) >= meta;
    },
    isEnoughMat(mat, meta) {
      if (!mat || !meta) return false;

      let sum = 0;
      mat.forEach((m) => {
        sum += m.real_quantity;
      });

      if (sum >= meta) {
        return true;
      } else {
        return false;
      }
    },

    sumCanUse(mat, meta) {
      let sum = 0;
      mat.forEach((m) => {
        sum += m.real_quantity;
      });

      if (sum >= meta) {
        return meta;
      } else {
        return sum;
      }
    },
    updateOperations() {
      CrudService.updatePieceOperations(this.order.pieza.id, {
        operaciones: this.order.pieza.operaciones,
      }).then((response) => {
        console.log(response);
        //this.order.pieza.operaciones = response.data.operaciones;
      });
    },
    addOperation() {
      this.order.pieza.operaciones.push({
        name: this.operation,
        estimated: this.operation_estimated,
      });
    },
    getOperators() {
      OperatorService.getOperators().then((response) => {
        this.operators = response;
      });
    },
    otiName(id) {
      // str pad left 0000
      return (
        "#" +
        ("000" + this.order.production_id).slice(-3) +
        "-" +
        ("0000" + id).slice(-4)
      );
    },

    getOrder() {
      //CrudService.getCRUD("cliente").then((data) => (this.clients = data));
      CrudService.getCRUD('api/machines').then(
      (data) => (this.machines = data)
    );

      CrudService.getCRUD("api/operarios").then((data) => (this.users = data)); 


      CrudService.getCRUD("api/orden/" + this.$route.params.id)
        .then((data) => {
          this.order = data.oti
          
          
        })
        .then(() => {


          if(this.order.materials.length > 0){
            //alert('mats condigurados')
            // redirect to oti
            this.$router.push({ name: "oti", params: { id: this.order.id } });
          }

          this.loaded = true;

          if(this.order.mpc){

            CrudService.getCRUD("api/materiales?stock=true&fromclient="+ this.order.op.client.id).then(
        (data) => 
        {
          // filter data if real_quantity >= 1
          this.materiales = data.filter((m) => m.real_quantity >= 1);
          
        }
      );
          }else{
            
        CrudService.getCRUD("api/materiales?stock=true").then(
        (data) => 
        {
          // filter data if real_quantity >= 1
          this.materiales = data.filter((m) => m.real_quantity >= 1);
          
        }
      );
          }
        }).then(() => {
          this.loaded = true;
        })

    },
    searchMaterials(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredMaterials = [...this.materiales];
        } else {
          this.filteredMaterials = this.materiales.filter((prod) => {
            return prod.select_data
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    giveMeName(id, table) {
      if (table == "cliente") {
        let exist = this.clients.find((client) => client.id === id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        }
      }

      if (table == "maquinaria") {
        let exist = this.machines.find((machine) => machine.id === id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        }
      }

      if (table == "usuario") {
        let exist = this.users.find((user) => user.id === id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        }
      }

      if (table == "material") {
        let exist = this.materials.find((material) => material.id === id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        }
      }
    },
  },
};
</script>

<style scoped>
.field > label {
  color: #9a9a9a;
  letter-spacing: 0.64px;
  font-size: 16px;
  text-transform: uppercase;
}

.field > span {
  display: block;
  text-align: left;
  letter-spacing: 0px;
  color: #0c0c0c;
  opacity: 1;
  border: none;
  background: transparent;
  font-size: 20px;
  line-height: 25px;
}

.plan_button:hover {
  border: 1px solid #d8d8d8;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 483px;
  height: 56px;
}
</style>